<template>
  <Dialog v-show="active" class="backdrop-blur-sm">
    <template #header>
      <div class="mb-8">
        <div class="flex justify-between items-start">
          <h1 class="font-semibold text-[32px] text-brand-black leading-[110%]">Remote Work</h1>
          <img src="@/assets/icons/close-circle.svg" class="cursor-pointer" @click="$emit('close')" alt="" />
        </div>
        <p class="text-[16px] text-[#8F8F8F] leading-[160%]">Your ideal balance between office work and home work.</p>
      </div>
    </template>

    <div class="mb-8">
      <div class="flex items-center mb-8" v-for="item in workTypes" :key="item.key">
        <input
          :id="`checkbox-item-${item.key}`"
          type="checkbox"
          :value="item.key"
          :checked="filterVars.includes(item.value)"
          @change="updateFilter($event, 'work_type', item.value)"
          class="w-4 h-4 text-brand-black bg-gray-100 rounded border-gray-300 focus:ring-brand-black dark:focus:ring-brand-black focus:ring-2 mr-2"
        />
        <label for="checkbox-1" class="grow ml-3 text-sm font-normal text-brand-black dark:text-brand-black flex justify-between items-center">
          <div>
            <div class="flex items-center">
              <span class="inline-block mr-4 text-[14px] capitalize">{{ item.value }}</span>
              <!-- <span class="px-6 py-1 rounded-2xl bg-light-yellow text-brand-black">30</span> -->
            </div>
          </div>
          <img src="@/assets/icons/chevron-right.svg" />
        </label>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between">
        <div class="flex">
          <button class="text-white text-[15px] px-8 py-2 bg-brand-black rounded-lg mr-4" @click="$emit('on-apply')">Explore</button>
          <button class="text-brand-black text-[15px] px-8 py-2 rounded-lg border border-brand-black" @click="$emit('close')">Cancel</button>
        </div>

        <button class="text-brand-primary text-[15px]" @click="$emit('clear-key', 'work_type')">Clear all</button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/partials/Dialog.vue';

const emit = defineEmits(['update']);

const props = defineProps({
  active: { type: Boolean, default: false },
  workTypes: { type: Array, default: [] },
  filterVars: { type: Object, default: [] },
});

const updateFilter = (event, key, value = '') => {
  emit('update', event, key, value);
};
</script>
