<template>
  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-brand-black text-center">
        <h1 class="text-brand-black text-[32px] md:text-[50px] font-medium">Want to discover more Opportunities?</h1>

        <p class="text-[14px] md:text-[18px] leading-[150%] md:leading-[130%] mb-6 text-brand-black">
          Finding top talent in a highly competitive market can be daunting, but with CareerBuddy's help, we were able to attract
          <br class="hidden md:inline-block" />
          and hire the best fit for our team. Their professionalism and attention to detail ensured we got candidates that matched
          <br class="hidden md:inline-block" />
          our company's culture and values”
        </p>
        <br />
        <div>
          <a href="/contact" class="rounded-2xl px-8 py-4 text-center bg-brand-black text-white text-[15px] md:text-[18px]">Join CareerBuddy</a>
        </div>
      </div>
    </div>
  </div>
</template>
