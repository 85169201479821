<template>
  <div class="flex w-full justify-center mt-10 mb-8 px-0 md:px-5 lg:px-0">
    <div class="border-2 w-full border-[#EFF3FA]">
      <div class="bg-white p-3 md:flex items-center rounded-lg shadow-md border border-brand-black gap-x-4">
        <div class="md:w-1/2 flex items-center md:border-r mb-3 md:mb-0">
          <img src="@/assets/icons/search.svg" class="sm:mr-2" alt="" />
          <input
            type="keyword"
            :value="filter.search"
            @change="updateFilter($event, 'search')"
            id="keyword"
            class="text-sm placeholder:text-[#B1B1B1] placeholder:text-[16px] w-full p-3 appearance-none focus:outline-none sm:h-[45px] border-b md:border-0 sm:w-full sm:block"
            placeholder="Search by job, keyword, or company"
          />
        </div>

        <div class="md:flex flex-row items-center justify-items-center w-full gap-x-4">
          <div class="w-full md:w-1/2 flex items-center md:border-r mb-3 md:mb-0">
            <img src="@/assets/icons/location.svg" class="sm:mr-2" alt="" />
            <select
              class="w-full border-none focus:outline-none focus:ring-transparent text-[#121212] text-[16px] sm:h-[45px] border-b md:border-0 sm:w-full sm:block"
              @change="updateFilter($event, 'work_type')"
            >
              <option value="">All location</option>
              <option v-for="item in workEnmus" :key="item" :value="item.key" :selected="filterVars.includes(item.value)">
                {{ item.value }}
              </option>
            </select>
          </div>

          <div class="w-full md:w-1/2 flex items-center mb-3 md:mb-0">
            <img src="@/assets/icons/filter.svg" class="sm:mr-2" alt="" />
            <select
              @change="updateFilter($event, 'type')"
              class="w-full border-none focus:outline-none focus:ring-transparent text-[#121212] text-[16px] sm:h-[45px] border-b md:border-0 sm:w-full sm:block"
              name=""
              id=""
            >
              <option value="">Job type</option>
              <option v-for="item in TypeEnmus" :key="item" :value="item.key" :selected="filterVars.includes(item.value)">
                {{ item.value }}
              </option>
            </select>
          </div>
        </div>

        <div>
          <button
            class="bg-brand-black md:w-[168px] h-[52px] rounded-[8px] text-white font-bold sm:text-center sm:items-center sm:justify-center w-full mt-4 md:mt-0 block md:inline-block"
            @click="$emit('on-apply')"
          >
            Find Jobs
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap gap-4 md:gap-8 items-center" v-if="showFilterControls">
    <div
      class="inline-flex justify-center md:justify-start space-x-2 px-8 py-4 border border-[#C2C2C2] rounded-xl cursor-pointer w-full md:w-auto"
      @click="remoteDialog = true"
    >
      <img src="@/assets/icons/plane.svg" class="w-[24px] h-[24px]" alt="" />
      <span class="text-[#8B8B8B] text-[16px]">Remote Work</span>
    </div>
    <div
      class="inline-flex justify-center md:justify-start space-x-2 px-8 py-4 border border-[#C2C2C2] rounded-xl cursor-pointer w-full md:w-auto"
      @click="occuDialog = true"
    >
      <img src="@/assets/icons/briefcase.svg" class="w-[24px] h-[24px]" alt="" />
      <span class="text-[#8B8B8B] text-[16px]">Occupations</span>
    </div>
    <!-- <div class="inline-flex px-8 py-4 border border-[#C2C2C2] rounded-xl cursor-pointer" @click="sectorDialog = true">
      <img src="@/assets/icons/sector.svg" class="sm:mr-2 w-[24px] h-[24px]" alt="" />
      <span class="text-[#8B8B8B] text-[16px]">Sector</span>
    </div> -->
    <button
      class="bg-brand-black md:w-auto px-8 h-[52px] rounded-xl text-white font-bold sm:text-center sm:items-center sm:justify-center w-full mt-4 md:mt-0 block md:inline-block"
    >
      All Filters
    </button>
  </div>

  <remote-filter
    :active="remoteDialog"
    :work-types="workEnmus"
    :filterVars="filterVars"
    @close="remoteDialog = false"
    @clear-key="
      remoteDialog = false;
      clearFilterKey($event);
    "
    @update="updateFilter"
    @on-apply="
      remoteDialog = false;
      $emit('on-apply');
    "
  />
  <occupation-filter
    :active="occuDialog"
    :occupations="categories"
    :filterVars="filterVars"
    @close="occuDialog = false"
    @clear-key="
      occuDialog = false;
      clearFilterKey($event);
    "
    @update="updateFilter"
    @on-apply="
      occuDialog = false;
      $emit('on-apply');
    "
  />
  <sector-filter :active="sectorDialog" @close="sectorDialog = false" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Search from '@/assets/icons/search.svg?inline';
import RemoteFilter from '@/components/jobs/RemoteFilter.vue';
import OccupationFilter from '@/components/jobs/OccupationFilter.vue';
import SectorFilter from '@/components/jobs/SectorFilter.vue';

const props = defineProps({
  showFilterControls: { type: Boolean, default: false },
  filter: { type: Object, default: {} },
  filterVars: { type: Object, default: [] },
  categories: { type: Object, default: [] },
});

const emit = defineEmits(['onClose', 'update', 'clear', 'on-apply']);

const remoteDialog = ref(false);
const occuDialog = ref(false);
const sectorDialog = ref(false);

//const categories = ref([]);
const workType = ref('');
const search = ref('');
const type = ref('');
const store = useStore();

const updateFilter = (event, key, value = '') => {
  let dataVal = '';
  if (event.target.type == 'select-one') {
    dataVal = event.target.options[event.target.options.selectedIndex].getAttribute('data-value');
  }

  emit('update', event, key, dataVal ? dataVal : value);
};

const clearFilterKey = (key) => {
  emit('clear', key);
};

const workEnmus = computed(() => {
  return store.getters['getEnums']?.job_work_types || [];
});
const TypeEnmus = computed(() => {
  return store.getters['getEnums']?.job_types || [];
});

onMounted(async () => {
  if (store.getters['getEnums']?.job_work_types === undefined) await store.dispatch('getEnums');
  //categories.value = await store.dispatch('getJobCategory');
});
</script>
